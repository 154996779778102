import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/nigeria/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { CaretRightIcon } from "../../images/Icons";
import { Helmet } from "react-helmet";

const AboutUsPage = () => {
  return (
    <Layout pageTitle="About Us - Moniepoint MFB">
      <Helmet>
        <link rel="canonical" href="https://moniepoint.com/ng/about-us" />
        <link rel="alternate" href="https://moniepoint.com/ng/about-us" hreflang="x-default" />
        <link rel="alternate" href="https://moniepoint.com/ng/about-us" hreflang="en-ng" />
      </Helmet>
      <HeroContainer>
        <div className="hero-content-wrapper">
          <h1 className="title">
            <Trans>About Us</Trans>
          </h1>
          <div className="content">
            <h6 className="description">
              <Trans>
                Moniepoint Microfinance Bank is the definitive bank for small and medium-sized businesses in Nigeria and is regulated by the Central Bank of Nigeria (CBN). Moniepoint MFB was also awarded by the CBN in 2022 as the most inclusive payment platform in the country.
              </Trans><br />
              <br />
              Moniepoint MFB received its license in February 2022 and helps over 1.5 million businesses with banking, payments processing, access to loans and business management tools. With these solutions,
              businesses on the moniepoint platform process about 8 trillion Naira monthly.
              <br />
              <br />
              Moniepoint MFB operates the largest distribution network for financial services in Nigeria,
              and over 33 million people use their cards on our POS terminals monthly across every local government in Nigeria.
               </h6>
            <StaticImage
              alt="..."
              src="../../images/nigeria/about-us/about-us.jpg"
              className="hero-image grow"
            />
          </div>
          <div className="content">
            <h6 className="description">
              <br />
              We’re headquartered in Oyo, with support offices in 33 states and thousands of business relationship managers directly assigned to each business owner. This demonstrates our commitment
              to ensuring that businesses on the Moniepoint platform get access to all the help they need to grow their business.
              <br />
              <br />
              In August 2023, we launched our Personal banking product to further support the businesses that use our products, by banking their customers and employees.
              Through this product, individual users can get reliable cards, as well as bank accounts for transfers and bill payments.
              <br />
              <br />
              Moniepoint Microfinance Bank is run by a team of veteran bankers and technologists
              working to provide the most seamless and secure experience for everyone using our platform.
              <br />
              <br />
              <Link className="link" to="/about-us"><span>Read more about our journey</span>  <CaretRightIcon size={20} /> </Link>
            </h6>
          </div>
        </div>
      </HeroContainer>
    </Layout>
  );
};

export default AboutUsPage;
export const query = graphql`
  query {
    locales: allLocale(
      filter: { ns: { in: ["common", "nigeria"] }, language: { eq: "en" } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background-color: white;

  .hero-content-wrapper {
    max-width: 1440px;
    padding: 60px 150px;
    margin: auto;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 60px;
    }

    .title {
      font-family: "Founders Grotesk";
      font-size: 64px;
      font-weight: 500;
      line-height: 76px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 40px;
    }
    .content {
      display: flex;
      gap: 79px;
    }
    .description {
      font-family: Inter;
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: justify;
      width: fit-content;
    }
    .hero-image {
      border-radius: 16px;
      max-width: 548px;
      height: 430px;
      width: 100%;
    }

    .link {
      text-decoration: none;
      color: rgba(3, 87, 238, 1);

      :hover {
        color: rgba(3, 87, 238, .8)
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .hero-content-wrapper {
      padding: 24px 20px;

      .title {
        font-size: 40px;
        line-height: 53px;
      }
      .content {
        flex-direction: column;
        gap: 48px;
      }
      .description {
        order: 2;
        font-size: 16px;
      }
      .hero-image {
        border-radius: 1px;
        margin: 0 -20px;
        height: auto;
        max-width: 100vw;
        width: 100vw;
      }
    }
  }
`;
